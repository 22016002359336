import {AppDispatch, RootState} from "../redux";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

export const cls = (...classnames: string[]) => {
    return classnames.join(" ");
};

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

